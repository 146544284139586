import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import { Container, Button, Row, Col, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Home({ data }) {
  return (
    <Layout>
      <SEO title="Home" />
      <div class="px-4 py-5 text-center background-yellow border border-dark">
        <h1 class="display-5 fw-bold">Hi, nice to meet you 👋</h1>
        <div class="col-lg-6 mx-auto">
          <p class="lead mb-4">Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.</p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <ul class="list-unstyled d-flex">
              <Link to=""><li><i class="fab fa-instagram fa-3x"></i></li></Link>
              <Link to=""><li><i class="fab fa-facebook fa-3x"></i></li></Link>
            </ul>
          </div>
        </div>
      </div>
      <br></br>

      <Container>
        <br></br>
        <h1>Artikel Pilihan</h1>
        <br></br>

        <Row xs={1} md={2} lg={2}>
          {data.allWpPost.nodes.map(node => (
            <Col md={6}>
              <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div class="col p-4 d-flex flex-column position-static">
                  <strong class="d-inline-block mb-2 text-primary">
                    {" "}
                    {node.categories.nodes.map((category, index) => [
                      index > 0 && ", ",
                      <Link key={index}>
                        {category.name}
                      </Link>,
                    ])}
                  </strong>
                  <h3 class="mb-0 SerifFont"><b>{node.title}</b></h3>
                  <div class="mb-1 text-muted">{node.date}</div>
                  <p class="card-text mb-auto"><div dangerouslySetInnerHTML={{ __html: node.excerpt }} /></p>
                  <Link to={node.uri}><Button variant="outline-dark">Read More</Button></Link>
                  <div className="card-text mb-auto">
                    {" "}
                    {node.tags.nodes.map((tag, index) => [
                      index > 0 && ", ",
                      <Link key={index} to={tag.link}>
                        #{tag.name}
                      </Link>,
                    ])}
                  </div>
                </div>
                <div class="col-auto d-none d-lg-block">
                  <Image variant="top" src={node.featuredImage?.node?.sourceUrl} width="300" height="240" alt={node.featuredImage?.node?.altText} />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

    </Layout>
  )
}

export const PageQuery = graphql`
{
  allWpPost(sort: {fields: [date], order: DESC}, limit: 2) {
    nodes {
      title
      excerpt
      slug
      uri
      date(formatString: "MMMM Do, YYYY")
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
      author {
        node {
          lastName
          firstName
          id
          avatar {
            url
          }
        }
      }
      tags {
        nodes {
          name
          link
        }
      }
      categories {
        nodes {
          name
          link
        }
      }
    }
  }
}

`